export const columns = [
  {
    title: "SL.No",
    dataIndex: "SLNo",
  },
  {
    title: "Collage Name",
    dataIndex: "CollageName",
  },
  {
    title: "Duration",
    dataIndex: "duration",
  },
  {
    title: "Exam Accepted",
    dataIndex: "ExamAccepted",
  },
  {
    title: "Course",
    dataIndex: "Course",
  },
  {
    title: "Average Package",
    dataIndex: "AveragePackage",
  },
];
export const data = [
  {
    key: "1",
    SLNo: 1,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "2",
    SLNo: 2,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "3",
    SLNo: 3,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "4",
    SLNo: 4,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "5",
    SLNo: 5,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "6",
    SLNo: 6,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "7",
    SLNo: 7,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "8",
    SLNo: 8,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "9",
    SLNo: 9,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
  {
    key: "10",
    SLNo: 10,
    CollageName: "IIM Bangelore",
    duration: "2 Years",
    ExamAccepted: "CAT, CUET",
    Course: "MBBS, B.ED",
    AveragePackage: "60 LPA",
  },
];
